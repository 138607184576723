export const Prices = [
    {
      _id: 0,
      name: "₹100 to 499",
      array: [100, 499],
    },
    {
      _id: 1,
      name: "₹500 to 999",
      array: [500, 999],
    },
    {
      _id: 2,
      name: "₹1000 to 1499",
      array: [1000, 1499],
    },
    {
      _id: 3,
      name: "₹1500 to 1999",
      array: [1500, 1999],
    },
    {
      _id: 4,
      name: "₹2000 to 2499",
      array: [2000, 2499],
    },
    {
      _id: 5,
      name: "₹2500 or more",
      array: [2500, 9999],
    },
  ];
  